import * as React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Container from '../../components/container'
import DemoForm from '../../components/demo_form'
import { StaticImage } from 'gatsby-plugin-image'
import RoiCalculator from '../../components/roi_calculator'

const RoiCalculatorPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Measure Digital Retailing ROI'
      description='Digital-retailing success is measured across KPIs that ultimately add to your bottom line.'
    />
    <div className='Section Section--dark xs:pb-0'>
      <StaticImage
        src='../../images/es_roi_bg.jpg'
        alt=''
        placeholder='blurred'
        imgClassName='object-top'
        className='absolute inset-0'
        quality={100}
      />
      <Container className='relative'>
        <h1 className='font-semi-bold text-xl sm:text-4vw mt-0'>
          Measure Digital Retailing ROI
        </h1>
        <p className='sm:text-lg font-light mb-3 sm:mb-4 md:pb-3'>
          Digital-retailing success is measured across KPIs that ultimately add to your bottom line.
        </p>
        <div className='grid gap-4 lg:gap-5 md:grid-cols-fill-fit'>
          <div>
            <div className='bg-white text-body p-2 sm:p-3 rounded-md'>
              <h2 className='text-base sm:text-md font-semi-bold mt-0'>
                <span className='grid grid-cols-fit-fill gap-1/2em items-center'>
                  <img
                    alt=''
                    src='https://media.roadster.com/dealersales/EM_Icon_3.1.svg'
                    className='opacity-50'
                    style={{
                      width: '3.125em',
                      height: '3.125em'
                    }}
                  />
                  Calculate ROI Using Digital Retail Tools for:
                </span>
              </h2>
              <RoiCalculator />
            </div>
          </div>
          <div className='md:order-first sm:text-md md:mt-3 md:pt-1'>
            <div style={{ maxWidth: '42em' }}>
              <p className='text-md sm:text-lg font-semi-bold'>
                Dealers using Roadster recognize ROI improvements over these major categories.
              </p>
              <p>
                Fill in the blank fields and see how you can benefit from using Roadster online and in your store.
              </p>
            </div>
            <div className='border-t border-current opacity-30 my-3' />
            <div style={{ maxWidth: '42em' }}>
              <p>
                Roadster’s Express Storefront offers digital retailing capabilities — and so much more.
              </p>
              <h2 className='text-lg sm:text-xl font-light'>
                <span className='font-semi-bold'>2</span>X More Engagement
              </h2>
              <p>
                Dealers who use our digital-sales platform report twice as much online engagement as the industry average.
              </p>
              <h2 className='text-lg sm:text-xl font-light'>
                <span className='font-semi-bold'>70</span>% More Efficiency
              </h2>
              <p>
                Our omnichannel-commerce capabilities enable dealer-partners to work multiple deals at once and sell 17 cars per person per month, on average — compared to the industry average of 10.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section Section--dark'>
      <Container>
        <DemoForm invert={true} />
      </Container>
    </div>
  </Layout>
)

export default RoiCalculatorPage
