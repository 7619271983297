/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react'
import { styled } from '../styles/stitches.config'
import { Formik, Field } from 'formik'
//import Button from './button'
import Link from './link'
import TextInput from './text_input'

const DR_CLOSE_RATE = .12
const CURRENT_UNITS_PER_SALESPERSON = 10.1
const SALES_COMP_VEH = 371

const StyledValue = styled('span', {
  paddingRight: '$2',
  marginRight: 1,
  '&:empty::before': {
    content: '"0"',
    color: '$silver'
  }
})

const FormLink = styled(Link, {
  paddingY: '$2',
  borderBottomWidth: '$2',
  borderBottomColor: 'transparent',
  variants: {
    disabled: {
      true: {
        opacity: 1,
        borderBottomColor: 'currentColor'
      }
    }
  }
})

const INPUT_PROPS = {
  component: TextInput,
  type: 'number',
  required: true,
  alignRight: true,
  width: '8.5em',
  marginBottom: false
}

function formatVal (x, isCurrency) {
  if (isNaN(x) || !isFinite(x) || x === 0) {
    return ''
  }
  x = Math.round(x)
  if (typeof x === 'number' && !isCurrency) {
    x = x.toString();
  }
  return (
    x.toLocaleString('en-US', {
      style: isCurrency ? 'currency' : null,
      currency: isCurrency ? 'USD' : null,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
  )
}


const RoiCalculator = () => {

  const [activeForm, setActiveForm] = useState('form1')

  return (<>
    <ul className='list-reset text-center flex gap-x-2 sm:gap-x-3 lg:gap-x-4 justify-between text-sm lg:text-base uppercase font-semi-bold mb-3'>
      <li>
        <FormLink
          variant='muted'
          disabled={activeForm === 'form1'}
          onClick={() => setActiveForm('form1')}
          children='Website Leads Only'
        />
      </li>
      <li>
        <FormLink 
          variant='muted'
          disabled={activeForm === 'form2'}
          onClick={() => setActiveForm('form2')}
          children='All Internet Leads'
        />
      </li>
      <li>
        <FormLink
          variant='muted'
          disabled={activeForm === 'form3'}
          onClick={() => setActiveForm('form3')}
          children='Efficiency + Cost Savings'
        />
      </li>
    </ul>
    
    {activeForm === 'form1' &&
      <Formik
        initialValues={{
          prospects: '300',
          close_rate: '6',
          gross_profit: '2192'
        }}
      >
        {({ values, handleBlur, setFieldValue }) => {
          const sales1 = values.prospects * (values.close_rate / 100);
          const sales2 = values.prospects * DR_CLOSE_RATE;
          const incrementalSales = sales2 - sales1;
          const incrementalProfit = incrementalSales * values.gross_profit;
          return (
            <div className='grid auto-rows-fr sm:text-md leading-tight'>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Online Retailing Prospects*</span>          
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='prospects'
                  value={values.prospects}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Your Average Close Rate (percent)*</span>
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='close_rate'
                  value={values.close_rate}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Sales</span>
                <StyledValue
                  children={formatVal(sales1)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Average gross profit per car*</span>
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='gross_profit'
                  value={values.gross_profit}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold text-gray'>
                <span>DR close rate (Roadster Avg)</span>
                <StyledValue
                  children={`${DR_CLOSE_RATE * 100}%`}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Sales</span>
                <StyledValue
                  children={formatVal(sales2)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Incremental Sales</span>
                <StyledValue
                  children={formatVal(incrementalSales)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Incremental Profit/Month</span>
                <StyledValue
                  children={formatVal(incrementalProfit, true)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Annualized Profit</span>
                <StyledValue
                  children={formatVal(incrementalProfit * 12, true)}
                />
              </div>
            </div>
        )}}
      </Formik>
    }

    {activeForm === 'form2' &&
      <Formik
        initialValues={{
          leads: '909',
          close_rate: '7.26',
          gross_profit: '2192'
        }}
      >
        {({ values }) => {
          const sales1 = values.leads * (values.close_rate / 100);
          const sales2 = values.leads * DR_CLOSE_RATE;
          const incrementalSales = sales2 - sales1;
          const incrementalProfit = incrementalSales * values.gross_profit;
          return (
            <div className='grid auto-rows-fr text-md'>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Internet Leads*</span>          
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='leads'
                  value={values.leads}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Average Close Rate (percent)*</span>
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='close_rate'
                  value={values.close_rate}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Sales</span>
                <StyledValue
                  children={formatVal(sales1)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Average gross profit per car*</span>
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='gross_profit'
                  value={values.gross_profit}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold text-gray'>
                <span>DR close rate (Roadster Avg)</span>
                <StyledValue
                  children={`${DR_CLOSE_RATE * 100}%`}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Sales</span>
                <StyledValue
                  children={formatVal(sales2)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Incremental Sales</span>
                <StyledValue
                  children={formatVal(incrementalSales)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Incremental Profit/Month</span>
                <StyledValue
                  children={formatVal(incrementalProfit, true)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Annualized Profit</span>
                <StyledValue
                  children={formatVal(incrementalProfit * 12, true)}
                />
              </div>
            </div>
        )}}
      </Formik>
    }

    {activeForm === 'form3' &&
      <Formik
        initialValues={{
          volume: '150',
          gross_profit: '2192',
          goal_units: '17'
        }}
      >
        {({ values }) => {
          const avgSalespersonComp = SALES_COMP_VEH * CURRENT_UNITS_PER_SALESPERSON * 12
          const revisedSalesComp = avgSalespersonComp / (values.goal_units * 12)
          const monthlySavings = (SALES_COMP_VEH - revisedSalesComp) * values.volume

          return (
            <div className='grid auto-rows-fr text-md'>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Monthly Sales Volume*</span>          
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='volume'
                  value={values.volume}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Average gross profit per car (NADA Avg)*</span>
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='gross_profit'
                  value={values.gross_profit}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold text-gray'>
                <span>Current Units per salesperson (NADA Avg)</span>
                <StyledValue
                  children={CURRENT_UNITS_PER_SALESPERSON}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold text-gray'>
                <span>Sales Comp/Veh (NCM Avg)</span>
                <StyledValue
                  children={formatVal(SALES_COMP_VEH, true)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Avg Salesperson Comp</span>
                <StyledValue
                  children={formatVal(avgSalespersonComp, true)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2'>
                <span>Goal Units per salesperson*</span>
                <Field
                  {...INPUT_PROPS}
                  className='text-inherit'
                  name='goal_units'
                  value={values.goal_units}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Revised Sales Comp/Veh</span>
                <StyledValue
                  children={formatVal(revisedSalesComp, true)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Monthly Savings</span>
                <StyledValue
                  children={formatVal(monthlySavings, true)}
                />
              </div>
              <div className='grid grid-cols-fill-fit gap-3 items-center py-1 border-b border-darken2 font-semi-bold'>
                <span>Annual Savings</span>
                <StyledValue
                  children={formatVal(monthlySavings * 12, true)}
                />
              </div>
            </div>
        )}}
      </Formik>
    }
    
    <p className='text-sm text-silver mb-0 mt-3'>
      *Input your dealerships numbers
    </p>
    {/*
    <div className='grid grid-cols-fill-fit items-center mt-3'>
      <p className='text-sm text-gray m-0'>
        *Input your dealerships numbers
      </p>
      <Button
        type='submit'
        variant='dark'
        minWidth={true}
        children='Calculate Your ROI'
      />
    </div>
    */}
  </>)
}

export default RoiCalculator
